<template>
  <div
    class="keywordsTabContainer card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <productTargeting
      v-if="showAddCustomProductPanel"
      :widget-configs="tabConfig.customActionPanelConfigs"
      :context="context"
      campaign-type="sb"
      default-tab="Products"
    />
    <component
      :is="addCustomKeywordPanelConfig.ui_component"
      v-if="showAddCustomKeywordPanel"
      v-bind="addCustomKeywordPanelConfig.props"
      v-on="addCustomKeywordPanelConfig.events"
    />
    <CustomRecommendations
      v-else-if="
        Object.keys(recommendationPropsAndEvents.props.metadata).length !== 0
      "
      v-bind="recommendationPropsAndEvents.props"
      v-on="recommendationPropsAndEvents.events"
    />
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }}
          {{ targetingType.toUpperCase() }}
          {{ selections.length > 1 ? `'s` : '' }} selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :bid-suggestion="bidSuggestion"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length)
          "
          class="u-spacing-ph-l"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="selections.length && !adjustBids"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <dashboard-inflator
      class="u-width-100"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      :disable-envelop-width-class="true"
      @metadataUpdated="metadataUpdated"
      @widgetParamsChanged="widgetParamsChanged"
      @widgetParamsCollectionUpdated="widgetParamsCollectionUpdated"
    />
  </div>
</template>
<script>
import {
  deepReplaceObjectValues,
  updateExistingWidgetParams,
  saveExistingWidgetParams,
  findDimensionValueFromWidgetParams,
  adjustPayloadForTargets
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import CustomRecommendations from '@/pages/dashboard_service_campaign_management/common_components/custom-recommendation.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { addImportantKeywordsForBrandRecommendationComponentConfig } from '@/pages/entity_details/configs/amazon/recommendations-config.js';
import HttpService from '@/utils/services/http-service';
const productTargeting = () =>
  import('@/pages/entity_details/common_components/productTargeting.vue');
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const productAndCategoryComponent = () =>
  import('@/components/basic/productAndCategoryComponent.vue');
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

const commonRequestParams = (vueRef) => ({
  adgroup_sb_tab_keywordtargeting: {
    ':widget': 'adgroup_sb_tab_keywordtargeting',
    ':orderByList': [
      {
        dimension: 'ams_cm_targeting_spend',
        direction: 'DESC'
      }
    ],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    },
    leftJoin: {
      value: true,
      fn: async (data) => {
        const adFormatMap = {
          'Product Collection': 'productCollection',
          video: 'video'
        };
        const requestPayloadForBids = {
          campaignType: 'sb',
          adFormat:
            (vueRef?.campaignInfo?.data?.adFormat &&
              adFormatMap[vueRef?.campaignInfo?.data?.adFormat]) ||
            'productCollection',
          keywords: data.rows.map((item) => {
            return {
              keywordText: item?.ams_cm_targeting_keyword_text,
              matchType: item?.ams_cm_targeting_match_type?.toLowerCase()
            };
          })
        };
        try {
          const suggestedBidsApiResponse = await HttpService.post(
            'GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER',
            requestPayloadForBids
          );
          for (const item of data.rows) {
            for (const suggKeyword of suggestedBidsApiResponse?.data
              ?.keywordRecommendations) {
              if (
                item?.ams_cm_targeting_keyword_text === suggKeyword?.keyword &&
                item?.ams_cm_targeting_match_type?.toLowerCase() ===
                  suggKeyword.matchType
              ) {
                item.ams_cm_targeting_bid_suggested = suggKeyword?.suggestedBid;
              }
            }
          }
          return cloneDeep(data);
        } catch {
          return data;
        }
      }
    }
  },
  adgroup_sb_tab_producttargeting: {
    ':widget': 'adgroup_sb_tab_producttargeting',
    ':orderByList': [
      {
        dimension: 'ams_cm_targeting_spend',
        direction: 'DESC'
      }
    ],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  },
  adgroup_sb_tab_keyword_recommendation: {
    ':widget': 'adgroup_sb_tab_keyword_recommendation',
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  }
});

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput,
    footerButtons,
    CustomRecommendations,
    productTargeting
  },
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata.page
    };
    const context = this;
    return {
      defaultSearchText: '',
      pageInfo,
      tabTypeMap: {
        keyword: 'adgroup_sb_tab_keywordtargeting',
        product: 'adgroup_sb_tab_producttargeting',
        'product & category': 'adgroup_sb_tab_product_category_targeting'
      },
      tabSearchKeyMap: {
        keyword: 'ams_cm_targeting_keyword_text',
        product: 'ams_cm_asin_search_text_targeting'
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      },
      recFilter: '',
      recCustomComponent:
        this.entityConfig.tabs[this?.tabMetadata?.component]
          ?.recCustomComponent,
      showRecCustomComponent: false,
      preferNoRecFilterList: [
        'RECOMMENDATIONS_KEYWORDS_EFFICIENT_SEARCHTERMS',
        'RECOMMENDATIONS_KEYWORDS_FOR_BRANDS',
        'RECOMMENDATIONS_KEYWORDS_CAMPAIGN_BESTPRACTICE'
      ],
      selectedRecommendation: {},
      isCustomActionStateVisible: false,
      showAddCustomProductPanel: false,
      bidValue: null,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      getState: null,
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      showAddCustomKeywordPanel: false,
      actionIdMethodMap: {},
      widget: {},
      bidSuggestion: { show: false },
      allowedActions: [
        {
          icon: 'task-status',
          title: 'EDIT STATUS',
          id: 'editStatus',
          dropDown: true,
          dropDownOptions: [
            { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
            { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
            { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
          ]
        },
        {
          icon: 'dollar',
          title: 'Adjust Bid',
          id: 'adjustBids'
        }
      ],
      tabConfig: null,
      recommendationWidgetParams: {},
      recommendationMetadata: {}
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    recommendationPropsAndEvents() {
      return {
        props: {
          footerOptions: [],
          showLastUpdatedDate: false,
          entityName: 'keyword',
          headingName: 'RECOMMENDATIONS TO ADD/ARCHIVE/ADJUST KEYWORDS',
          countKey: 'ams_cm_recommendation_count',
          labelKey: 'ams_cm_recommendation_type',
          recCustomComponent:
            (this.showRecCustomComponent &&
              this.recCustomComponent(this)[this?.recFilter?.value]) ||
            {},
          selectedRecommendation: this.selectedRecommendation,
          isCustomActionStateVisible: this.isCustomActionStateVisible,
          widgetRequestParams: this.recommendationWidgetParams,
          metadata: this.recommendationMetadata
        },
        events: {
          recommendationFilter: this.recommendationFilter,
          handleRecommendation: this.handleRecommendation
        }
      };
    },
    getAdGroupInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    addCustomKeywordPanelConfig() {
      return addImportantKeywordsForBrandRecommendationComponentConfig(
        this,
        'Enter List'
      );
    },
    headerOptions() {
      const that = this;
      const obj = {};
      obj.adgroup_sb_tab_keywordtargeting = {
        props: {
          headerOptions: [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: that.addCustomKeywordButtonClick,
                text: 'Add Custom Keywords',
                type: 'filled'
              },
              class: 'u-spacing-mr-xl'
            }
          ],
          // enableDownload: true,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: 4,
          headerContainerGridStyles: {
            'grid-template-columns': 'auto 1fr auto',
            display: this.selections.length ? 'none' : 'grid'
          },
          searchParams: {
            show: true,
            value: that.defaultSearchText,
            searchDimension:
              String(this.targetingType) === 'keyword'
                ? 'ams_cm_targeting_keyword_text'
                : 'ams_cm_asin_search_text_targeting',
            placeholder:
              String(this.targetingType) === 'keyword'
                ? 'Find a keyword'
                : 'Find a product'
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          }
        }
      };
      obj.adgroup_sb_tab_producttargeting = {
        props: {
          headerOptions: [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: that.addCustomProductsButtonClick,
                text: 'Add Custom Products/Category',
                type: 'filled'
              },
              class: 'u-spacing-mr-xl'
            }
          ],
          // enableDownload: true,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: 4,
          headerContainerGridStyles: {
            'grid-template-columns': 'auto 1fr auto',
            display: this.selections.length ? 'none' : 'grid'
          },
          searchParams: {
            show: true,
            value: that.defaultSearchText,
            searchDimension:
              String(this.targetingType) === 'keyword'
                ? 'ams_cm_targeting_keyword_text'
                : 'ams_cm_asin_search_text_targeting',
            placeholder:
              String(this.targetingType) === 'keyword'
                ? 'Find a keyword'
                : 'Find a product/category'
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          }
        }
      };
      return obj;
    },
    targetingType() {
      let targetingType = 'keyword';
      if (this.$route.path.includes('product_adgroup')) {
        targetingType = 'product';
      }
      return targetingType;
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        const initRequestParams = {
          ...this.initRequestParams,
          ...commonRequestParams(this)
        };
        this.updateEntireRequestParams(initRequestParams);
      },
      immediate: true,
      deep: true
    },
    tableDataComponentKey: {
      handler(newVal) {
        const globalRequestParams = this.getGlobalRequestParams();
        this.updateGlobalRequestParams({ ...globalRequestParams, key: newVal });
      }
    }
  },
  created() {
    Vue.component('adgroup_sb_tab_keywordtargeting', dashboardServiceTable);
    Vue.component('adgroup_sb_tab_producttargeting', dashboardServiceTable);
    Vue.component('customActionPanel', customActionPanel);
    Vue.component('productAndCategoryComponent', productAndCategoryComponent);
    Vue.component('iconTextActions', iconTextActions);
    const initRequestParams = {
      ...this.initRequestParams,
      ...commonRequestParams(this)
    };
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.updateSavedWidgetRequestParams(initRequestParams, this?.targetingType);
    this.updateEntireRequestParams(initRequestParams);

    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.$store.dispatch(this.tabConfig?.apiConfigs?.fetchAction, {
      entityId: this.entityId,
      entityType: this.entityConfig.entityType
    });
  },
  methods: {
    widgetParamsChanged(value) {
      const tab = this?.targetingType;
      const searchText = findDimensionValueFromWidgetParams(
        value,
        this.tabTypeMap?.[tab],
        this.tabSearchKeyMap[tab]
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    updateSavedWidgetRequestParams(initRequestParams, tab) {
      updateExistingWidgetParams(
        'campaignCreationState',
        initRequestParams[this.tabTypeMap[tab]],
        this.entityId
      );
    },
    widgetParamsCollectionUpdated(paramsCollection) {
      if (paramsCollection?.adgroup_sb_tab_keyword_recommendation) {
        this.recommendationWidgetParams =
          paramsCollection?.adgroup_sb_tab_keyword_recommendation;
        return;
      }
      const tab = this?.targetingType;
      saveExistingWidgetParams(
        'campaignCreationState',
        paramsCollection[this.tabTypeMap[tab]],
        this.$route?.params?.entityId
      );
    },
    metadataUpdated(data) {
      let recommendationMetadata = {};
      data.forEach((item) => {
        if (item.name === 'adgroup_sb_tab_keyword_recommendation') {
          recommendationMetadata = item;
        }
      });
      this.recommendationMetadata = recommendationMetadata;
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      this.checkForSuggestedBid();
    },
    transformBid(oldBid, selection) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        if (this.bidValue.inputValue === 'YY') {
          return parseFloat(oldBid);
        }
        if (this.bidValue.inputValue === 'XX') {
          return parseFloat(selection.data?.ams_cm_targeting_bid_suggested);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.callStateChange(dropDownSelection, selections);
      } else {
        if (actionId === 'adjustBids') {
          this.checkForSuggestedBid();
        }
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.bidSuggestion = { show: false };
      this.showAddCustomProductPanel = false;
      this.showAddCustomKeywordPanel = false;
    },
    callStateChange(dropDownSelection, selections) {
      const payloadArr = [];
      for (const selection of selections) {
        if (
          selection?.data?.ams_cm_adgroup_status === dropDownSelection[0].value
        ) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':entityId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_targeting_status,
            ':state': dropDownSelection[0].value,
            ':pageUrl': window.location.href,
            ':keywordText': selection?.data?.ams_cm_targeting_keyword_text,
            ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA',
            ':keywordId': selection?.data?.keyword_id,
            ':asin_id': selection?.data?.ams_cm_targeting_asin_id
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          const isTarget = reqValues[':matchType'].includes('TARGETING');
          adjustPayloadForTargets(isTarget, payload, selection);
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular:
            this.targetingType === 'keyword'
              ? 'Keyword Status'
              : 'Target Status',
          plural:
            this.targetingType === 'keyword'
              ? 'Keyword Statuses'
              : 'Target Statuses',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    checkForSuggestedBid() {
      this.bidSuggestion = { show: false };
      this.bidValue = null;
      if (
        this.selections.length === 1 &&
        this.selections[0]?.data?.ams_cm_targeting_bid_suggested
      ) {
        this.bidSuggestion = {
          show: true,
          title: 'Amazon Suggested Bid',
          value:
            this.selections[0]?.data?.ams_cm_targeting_bid_suggested.toString(),
          currentBid:
            this.selections[0]?.data?.ams_cm_targeting_base_bid.toString(),
          multipleSuggestions: false
        };
      } else {
        this.adjustBids = false;
      }
    },
    onSaveBid() {
      const payloadArr = [];
      for (const selection of this.selections) {
        const oldBid = selection?.data?.ams_cm_targeting_base_bid || 0;
        const newBid = this.transformBid(oldBid, selection);
        const payload = cloneDeep(this.bidChangeRequestPayload);
        const reqValues = {
          ':entityId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.keyword_id,
          ':keywordText': selection?.data?.ams_cm_targeting_keyword_text,
          ':asin_id': selection?.data?.ams_cm_targeting_asin_id,
          ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA'
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        const isTarget = reqValues[':matchType'].includes('TARGETING');
        adjustPayloadForTargets(isTarget, payload, selection);
        payloadArr.push(payload);
      }
      this.updateMetadataAction(payloadArr, {
        singular:
          this.targetingType === 'keyword' ? 'Keyword Bid' : 'Target Bid',
        plural:
          this.targetingType === 'keyword' ? 'Keyword Bids' : 'Target Bids',
        stopFetchApi: true
      });
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        entityName
      });
      this.closeActionBar();
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },
    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },
    recommendationFilter(val, label) {
      if (val) {
        const [value] = Object.values(val);
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
        }
      } else {
        this.recFilter = '';
      }
    },
    addCustomKeywordButtonClick() {
      this.closeActionBar();
      this.showAddCustomKeywordPanel = true;
    },
    addCustomProductsButtonClick() {
      this.closeActionBar();
      this.showAddCustomProductPanel = true;
    },
    callAddTargets(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(
          this.tabConfig?.addTargetsToCampain?.requestPayload
        );
        const reqValues = {
          ':expressionType': selection?.categoryId
            ? 'asinCategorySameAs'
            : 'asinSameAs',
          ':entityType': selection?.categoryId ? 'Category' : 'Product',
          ':campaignId': this.parentEntityId,
          ':adgroup_id': this.entityId,
          ':asin_title': selection?.categoryId
            ? selection?.category
            : selection.competitor_asin_title,
          ':pageUrl': window.location.href,
          ':asin_id': selection?.categoryId
            ? selection?.categoryId
            : selection.competitor_asin || 'NA',
          ':bid': selection.new_bid,
          ':product_url': selection.competitor_asin_product_url
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Campaign Target',
          plural: 'Campaign Targets',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
.keywordsTabContainer .header-container-cwc {
  padding: 2.4rem;
}
.keywordsTabContainer .recommendations-container .header-container-cwc {
  padding: 0px;
}
.keywordsTabContainer
  .custom_widget_body
  .header
  .custom-action-panel
  .custom-entity
  .split-table-section
  .action-header {
  padding-top: 0;
}
.keywordsTabContainer
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
.keywordsTabContainer .order-1 {
  order: 1;
}
.keywordsTabContainer .order-2 {
  order: 2;
}
.keywordsTabContainer .order-3 {
  order: 3;
}
.keywordsTabContainer .order-4 {
  order: 4;
}
</style>
